/**
 * Catch the Yeti Terms
 */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { mq } from '../../../hooks'
import { Body } from '../../../components/RichText'
import { Layout } from '../../../templates'

export default () => (
  <Layout>
    <>
      <div css={mq({ margin: ['1rem 1rem', '1rem 6rem'] })}>
        <Body>
          Accolade’s ‘Catch the Yeti’ Giveaway
          <br />
          <Body>
            NO PURCHASE OR PAYMENT NECESSARY TO ENTER. A PURCHASE OR PAYMENT OF
            ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING. VOID WHERE
            PROHIBITED OR RESTRICTED BY LAW.
          </Body>
          <Body>
            The Giveaway: Accolade is giving away a Yeti Hopper Flip 8 valued at
            $199.
          </Body>
          <Body>
            Eligibility: The Giveaway is open only to Lowe’s employees that
            attend 2018 INVEST Mooresville run by Lowe’s. Must be 18 years of
            age.
          </Body>
          <Body>
            How to Enter: Eligible Lowe’s employees may stop by Accolade’s table
            to enter. Participants just need to enter by texting YETI to 67793*
            or by providing basic contact information (name and email address)
            to an Accolade representative. One (1) entry per employee. All
            entries must be made onsite with the Accolade representative.
            Giveaway during the 2018 INVEST Mooresville event only.
          </Body>
          <Body>
            Winner Selection: One (1) winner will be randomly selected. Winners
            will be notified by phone or email and receive their prize via mail.
            Odds of winning depend on the number of entries received during the
            event. Except where prohibited by law, the winner’s acceptance of
            the prize constitutes permission for Accolade, Inc. to use the
            winner’s name and likeness in all forms of media, in perpetuity,
            without further compensation or approval, for any promotional,
            advertising, publicity or other purpose.
          </Body>
          <Body>
            *Message and data rates may apply if texting entry option used. View{' '}
            <a href="https://www.accolade.com/legal/policies/privacy">
              privacy policy
            </a>
            .
          </Body>
          <Body>
            ADDITIONAL TERMS: If there is a dispute as to the identity of an
            entrant, Accolade, Inc., as sponsor, reserves the right to deem that
            the entry was made by the authorized account holder of the e-mail
            address submitted. Compliance with the entry requirements will be
            determined by sponsor in its sole discretion. Submissions that
            violate, as determined by the sponsor, in its sole discretion, these
            entry requirements will be disqualified. Accolade will pay for the
            cost of mailing the prize to the winner. Method of shipping and
            carrier will be determined in Accolade’s sole discretion. THE PRIZE
            IS AWARDED “AS IS” AND ALL WARRANTIES ARE HEREBY DISCLAIMED. WINNER
            RESPONSIBLE FOR ANY APPLICABLE FEDERAL, STATE, OR LOCAL TAXES.
            ACCOLADE AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND SHAREHOLDERS
            ASSUME NO LIABILITY AND ARE NOT RESPONSIBLE FOR, AND BY ENTERING THE
            GIVEAWAY YOU HEREBY WAIVE AND RELEASE SUCH PARTIES FROM, ANY
            ACTIONS, CLAIMS, DAMAGES, LOSSES OR INJURIES OF ANY KIND ARISING IN
            CONNECTION WITH THE GIVEAWAY AND/OR THE RECEIPT, USE OR MISUSE OF
            ANY PRIZE.
          </Body>
        </Body>
      </div>
    </>
  </Layout>
)
